<template>
  <div class="card p-10">
    <div class="d-flex justify-content-between">
      <h1>裝置 : {{ deviceName }}</h1>
      <div>
        <button
          @click="addDialogVisible = true"
          type="button"
          class="btn btn-primary"
        >
          新增
        </button>
      </div>
    </div>

    <Datatable
      :table-data="tableData"
      :table-header="tableHeader"
      :enable-items-per-page-dropdown="true"
      :openTextEnd="false"
      :striped="true"
    >
      <template v-slot:cell-name="{ row: tableData }">
        <span :guid="tableData.data.guid">{{ tableData.name }}</span>
      </template>
      <template v-slot:cell-pointType="{ row: tableData }">
        <span>{{ tableData.pointType }}</span>
      </template>
      <template v-slot:cell-command="{ row: tableData }">
        <span>{{ tableData.command }}</span>
      </template>
      <template v-slot:cell-createTime="{ row: tableData }">
        <span>{{ tableData.createTime }}</span>
      </template>
      <template v-slot:cell-operate="{ row: tableData }">
        <el-dropdown>
          <span
            @mouseover="setCurrentCommand(tableData)"
            class="el-dropdown-link text-primary"
          >
            功能選單<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleDeleteDataPointCommand()"
                >刪除</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Datatable>
  </div>
  <CustomizeDialog v-model="addDialogVisible" title="新增點位指令" width="30%">
    <el-form
      label-position="left"
      :model="formData"
      :rules="rules"
      ref="formRef"
      class="form"
      label-width="110px"
    >
      <el-form-item label="名稱" prop="name">
        <el-input placeholder="請輸入名稱" v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="點位" prop="dataPoint">
        <el-select
          @change="onDataPointChange()"
          v-model="formData.dataPoint"
          placeholder="請選擇點位"
        >
          <el-option
            v-for="item in dataPointOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.dataPoint !== '' && formData.currentPointType == 'Enum'"
        label="指令"
        prop="enumCommand"
      >
        <el-select v-model="formData.enumCommand" placeholder="請選擇指令">
          <el-option
            v-for="item in enumCommandOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="formData.dataPoint !== '' && formData.currentPointType == 'Range'"
        label="指令"
        prop="enumCommand"
      >
        <div class="d-flex flex-column">
          <el-input-number
            v-model="formData.enumCommand"
            :min="rangeCommandRule.min"
            :max="rangeCommandRule.max"
          />
          <i class="fs-3 mt-2"
            >數值範圍:{{ rangeCommandRule.min }}~{{ rangeCommandRule.max }}</i
          >
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="d-flex justify-content-end">
        <button @click="onSubmit()" type="button" class="btn btn-primary">
          送出
        </button>
      </div>
    </template>
  </CustomizeDialog>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {
  getDataPointCommandByDevice,
  addDataPointCommand,
  deleteDataPointCommand,
} from "@/core/services/api/dataPointCommand";
import { useRouter, useRoute } from "vue-router";
import { getTargetDevice } from "@/core/services/api/device";
import { getLocalTimeString } from "@/core/utils/timeString";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CustomizeDialog from "@/components/v-dialog/CustomizeDialog.vue";

export default defineComponent({
  components: {
    Datatable,
    CustomizeDialog,
  },
  setup() {
    const route = useRoute();
    const deviceGuid = route.params.deviceGuid;
    const deviceName = route.params.deviceName;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "點位",
        key: "pointType",
        sortable: true,
      },
      {
        name: "指令",
        key: "command",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const commandList: any = reactive([]);
    const currentCommand: any = reactive({});
    const addDialogVisible = ref(false);
    const device: any = reactive({});
    const typeMap = {
      AliveStatus: "存活狀態",
      Power: "電源狀態",
      AirMode: "冷氣模式",
      AirFanSpeed: "冷氣風速",
      AirTemperatureSetting: "冷氣溫度設定",
      AirTemperatureIndoor: "冷氣室內溫度",
      DoorLock: "門鎖",
      BatteryPercentage: "電池",
      LightLevel: "亮度",
      LightColorTemperature: "色溫",
    };
    const enumerationMap = {
      UnKnow: "未知",
      Online: "線上",
      Offline: "離線",
      On: "電源開啟",
      Off: "電源關閉",
      Locked: "上鎖",
      UnLocked: "解鎖",
      AirCondition: "冷氣",
      Dehumidify: "除溼",
      Ventilation: "送風",
      Automatic: "自動",
      Heating: "暖氣",
      Manual: "手動",
    };
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      name: "",
      dataPoint: "",
      enumCommand: "",
      rangeCommand: 0,
      currentPointType: "",
    });
    const rules = {
      name: [
        {
          required: true,
          message: "請輸入名稱",
          trigger: "blur",
        },
      ],
      dataPoint: [
        {
          required: true,
          message: "請選擇點位",
          trigger: "change",
        },
      ],
      enumCommand: [
        {
          required: true,
          message: "請選擇指令",
          trigger: "change",
        },
      ],
    };
    const dataPointOption: any = reactive([]);
    const enumCommandOption: any = reactive([]);
    const rangeCommandRule: any = reactive({
      min: 0,
      max: 0,
    });

    const setCommandList = async () => {
      let request = await getDataPointCommandByDevice(deviceGuid);
      commandList.splice(0, commandList.length, ...request);
    };

    // 根據點位guid 取得data
    const getPointDataByGuid = (guid) => {
      let enumFilter = device.enumDataPoints.filter((o) => o.guid == guid);
      let rangeFilter = device.rangeDataPoints.filter((o) => o.guid == guid);
      if (enumFilter.length > 0) {
        return enumFilter[0];
      } else if (rangeFilter.length > 0) {
        return rangeFilter[0];
      }
    };

    // 根據valueType取得項目指令
    const getCommandByValueType = (valueType, command) => {
      if (valueType == "Enum") {
        return enumerationMap[JSON.parse(command.enumCommand).name];
      } else if (valueType == "Range") {
        return command.rangeCommand;
      }
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of commandList) {
        let pointData = getPointDataByGuid(item.dataPoint);
        tableData.push({
          data: item,
          name: item.name,
          pointType: typeMap[pointData.type],
          command: getCommandByValueType(pointData.valueType, item),
          createTime: getLocalTimeString(pointData.createTime),
        });
      }
    };

    const setCurrentCommand = (item) => {
      Object.assign(currentCommand, item);
    };

    const setDevice = async () => {
      let request = await getTargetDevice(deviceGuid);
      Object.assign(device, request);
    };

    const setDataPointOption = () => {
      dataPointOption.splice(0);
      let enumData = device.enumDataPoints.filter((o) =>
        o.permission.includes("Write")
      );
      let rangeData = device.rangeDataPoints.filter((o) =>
        o.permission.includes("Write")
      );
      for (const item of enumData) {
        dataPointOption.push({
          value: item.guid,
          label: typeMap[item.type],
          type: item.valueType,
        });
      }
      for (const item of rangeData) {
        dataPointOption.push({
          value: item.guid,
          label: typeMap[item.type],
          type: item.valueType,
        });
      }
    };

    const translateEnumeration = (name) => {
      if (enumerationMap[name] !== undefined) {
        return enumerationMap[name];
      } else {
        return name;
      }
    };

    const setEnumCommandOption = () => {
      enumCommandOption.splice(0);
      let enumCommandList = device.enumDataPoints.filter(
        (o) => o.guid == formData.dataPoint
      )[0];
      for (const item of enumCommandList.enumList) {
        enumCommandOption.push({
          value: JSON.stringify(item),
          label: translateEnumeration(item.name),
        });
      }
    };

    const setRangeCommandRule = () => {
      let rangeCommandList = device.rangeDataPoints.filter(
        (o) => o.guid == formData.dataPoint
      )[0];
      Object.assign(rangeCommandRule, {
        min: rangeCommandList.min,
        max: rangeCommandList.max,
      });
    };

    const onDataPointChange = () => {
      let commandType = dataPointOption.filter(
        (o) => o.value == formData.dataPoint
      )[0].type;
      formData.currentPointType = commandType;
      if (commandType == "Enum") {
        setEnumCommandOption();
      } else if (commandType == "Range") {
        setRangeCommandRule();
      }

      console.log("formData", formData);

      formData.enumCommand = "";
    };

    const onSubmit = () => {
      formRef.value!.validate((valid) => {
        if (valid) {
          Swal.fire({
            html: `
              <h4>您確定要新增嗎?</h4>
            `,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "新增",
            cancelButtonText: "取消",
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                name: formData.name,
                dataPoint: formData.dataPoint,
                enumCommand: formData.enumCommand,
                rangeCommand: formData.rangeCommand,
              };
              await addDataPointCommand(deviceGuid, request).then(() => {
                init();
                addDialogVisible.value = false;
                Swal.fire("新增成功!", "", "success");
              });
            }
          });
        }
      });
    };

    // 刪除點位裝置
    const handleDeleteDataPointCommand = async () => {
      Swal.fire({
        html: `
              <h4>您確定要刪除嗎?</h4>
            `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log("currentCommand",currentCommand)
          await deleteDataPointCommand(deviceGuid, currentCommand.data.guid).then(
            () => {
              init();
              Swal.fire("刪除成功!", "", "success");
            }
          );
        }
      });
    };

    const init = async () => {
      await setCommandList();
      console.log("commandList", commandList);
      await setDevice();
      console.log("device", device);
      setTableData();
      setDataPointOption();
    };
    init();

    return {
      tableData,
      initTableData,
      tableHeader,
      deviceName,
      setCurrentCommand,
      addDialogVisible,
      rules,
      formData,
      dataPointOption,
      enumCommandOption,
      onDataPointChange,
      rangeCommandRule,
      onSubmit,
      formRef,
      handleDeleteDataPointCommand,
    };
  },
});
</script>

<style>
</style>